import { Component, ChangeDetectionStrategy, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { concatMap, filter, finalize, first, map, shareReplay, tap } from 'rxjs/operators';
import { isNil, first as _first, toNumber } from 'lodash-es';
import { LoadingDirective } from '../../modules/loading/loading';
import { AppState } from '../../store/reducers';
import { selectCompany } from '../../store/selectors/companies.base';
import { compareShareClasses } from '../../helpers/compare-share-classes';
import { GqlCreateIdentityInput, GqlCurrency, GqlSiSharesLeftService } from '../../graphql/operations';
import { BigNumber } from 'bignumber.js';
import { selectShareIssue } from '../../store/selectors/share-issues.base';
import { SubSink } from 'subsink';
import { selectShareIssueParticipantById } from '../../store/selectors/share-issue-participants.selectors';
import { PaymentKind } from '@startuptools/common/participants';
import { GqlShareClass } from '../../graphql/base-types.graphql';
import { shareClassToInput } from '../../helpers/share-class-to-input';

interface Response {
  quantity: string;
  shareClass: GqlShareClass;
  claim: string;
}

interface Data {
  id?: string;
  paymentKind: PaymentKind;
  shareClass?: GqlShareClass;
  quantity?: string | null;
  claim?: string | null;
  currency: GqlCurrency;
  identity: GqlCreateIdentityInput;
  shareClasses: GqlShareClass[];
  limits?: Record<string, number>;
  settings: {
    fields: {
      shareClass?: boolean;
      quantity?: boolean;
      claim?: boolean;
    };
  };
  onSubmit: (response: Response) => Observable<unknown>;
}

@Component({
  selector: 'app-add-participant-dialog',
  templateUrl: './add-participant-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddParticipantDialogComponent implements OnInit, OnDestroy {
  compareShareClasses = compareShareClasses;
  PaymentKind = PaymentKind;
  @ViewChild('qLoading') qLoading: LoadingDirective;

  subs = new SubSink();

  formControls = {
    quantity: new FormControl<BigNumber | null>(!isNil(this.data.quantity) ? new BigNumber(this.data.quantity) : null),
    shareClass: new FormControl<GqlShareClass | null>(null),
    claim: new FormControl<BigNumber | null>(null),
  };
  form = new FormGroup(this.formControls);

  // @ts-expect-error TS2345
  selectedShareClassSub = new BehaviorSubject<ShareClass>(null);
  selectedShareClass$ = this.selectedShareClassSub.asObservable().pipe(filter(e => !isNil(e)));

  company$ = this.store.pipe(
    select(selectCompany),
    filter(e => !isNil(e)),
  );
  si$ = this.store.pipe(select(selectShareIssue));
  // @ts-expect-error TS2345
  sip$ = this.store.pipe(select(selectShareIssueParticipantById(this.data.id)));
  shareClasses$ = of(this.data.shareClasses);

  minClaim$ = this.si$.pipe(
    map(si => new BigNumber(si.pricePerShare || 0.01)),
    shareReplay(1),
  );

  sharesLeftToDistribute$ = combineLatest([this.selectedShareClass$, this.sip$, this.si$]).pipe(
    concatMap(([sc, sip, si]) =>
      this.gqlSiSharesLeft
        .fetch(
          {
            companyId: si.companyId,
            input: { id: si.id, shareClassName: sc?.name },
          },
          { fetchPolicy: 'no-cache' },
        )
        .pipe(
          map(res => res.data.siSharesLeft),
          map(optionsLeft => {
            if (!isNil(sip) && sip.shareClass.name === sc.name) {
              return optionsLeft + toNumber(sip.numberOfShares);
            }
            return optionsLeft;
          }),
        ),
    ),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<AddParticipantDialogComponent>,
    private store: Store<AppState>,
    private gqlSiSharesLeft: GqlSiSharesLeftService,
  ) {
    this.subs.sink = this.formControls.shareClass.valueChanges.subscribe(sc => this.selectedShareClassSub.next(sc));
    this.subs.sink = this.formControls.quantity.valueChanges.subscribe(() => this.calcClaimByShares().subscribe());
  }

  static open(dialog: MatDialog, data: Data): MatDialogRef<AddParticipantDialogComponent> {
    return dialog.open<AddParticipantDialogComponent, Data>(AddParticipantDialogComponent, {
      width: '600px',
      maxHeight: '80%',
      disableClose: true,
      data,
    });
  }

  ngOnInit() {
    this.si$.subscribe(si => si.pricePerShare);
    if (!isNil(this.data.claim)) {
      this.formControls.claim.setValue(new BigNumber(this.data.claim));
      this.formControls.claim.updateValueAndValidity();
    }
    if (!isNil(this.data.quantity)) {
      this.formControls.quantity.setValue(new BigNumber(this.data.quantity));
      this.formControls.quantity.updateValueAndValidity();
    }
    if (isNil(this.data.shareClass)) {
      this.shareClasses$.pipe(first()).subscribe(shareClasses => {
        if (isNil(shareClasses)) {
          return;
        }
        // @ts-expect-error TS2532
        const preSelected = this.data?.limits ? shareClasses.find(sc => this.data.limits[sc.name] > 0) : null;
        // @ts-expect-error TS2345
        this.formControls.shareClass.setValue(preSelected ?? _first(shareClasses));
      });
    } else {
      this.formControls.shareClass.setValue(this.data.shareClass);
    }
    this.formControls.shareClass.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  takeAll() {
    this.formControls.quantity.setValue(new BigNumber(this.getLimit().toFixed(0)));
  }

  getLimit() {
    // @ts-expect-error TS2532
    const baseLimit = this.data?.limits[this.formControls.shareClass.value?.name];
    if (this.formControls.shareClass.value?.name === this.data.shareClass?.name) {
      return baseLimit + toNumber(this.data.quantity ?? 0);
    }
    return baseLimit;
  }

  calcClaimByShares() {
    return combineLatest({ si: this.si$ }).pipe(
      first(),
      tap(({ si }) => {
        if (this.data.paymentKind !== PaymentKind.Offset || isNil(this.formControls.quantity.value)) {
          return;
        }
        const pricePerShare = new BigNumber(si.pricePerShare);
        this.formControls.claim.setValue(pricePerShare.times(this.formControls.quantity.value));
        this.form.updateValueAndValidity();
      }),
    );
  }

  submit() {
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      return;
    }
    this.calcClaimByShares().subscribe(() => {
      if (this.formControls.claim.value?.lte(0)) {
        return;
      }
      this.form.disable();
      this.qLoading?.start();
      const resp: Response = {
        // @ts-expect-error TS2322
        quantity: this.formControls.quantity.value?.toString(),
        // @ts-expect-error TS2322
        shareClass: shareClassToInput(this.formControls.shareClass.value),
        // @ts-expect-error TS2322
        claim: this.formControls.claim.value?.toString(),
      };
      this.data
        .onSubmit(resp)
        .pipe(
          first(),
          finalize(() => this.qLoading?.stop()),
        )
        .subscribe({
          next: () => this.dialogRef.close(),
          error: () => this.form.enable(),
        });
    });
  }
}
