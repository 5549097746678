import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { PaymentAccountKind } from '@startuptools/common/common';
import { first, map, switchMap, take, filter, startWith, finalize } from 'rxjs/operators';
import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { isNil, isEmpty, sortBy, uniqBy, isArray, first as _first } from 'lodash-es';
import { SubSink } from 'subsink';

import { bankCode } from '@startuptools/common/common';
import { LoadingDirective } from '../../modules/loading/loading';
import { AppState } from '../../store/reducers';
import { selectCompany } from '../../store/selectors/companies.base';
import { BankGrioService, BankGiro } from '../../injectables/bankgiro.service';

interface IPaymentKind {
  title: string;
  kind: PaymentAccountKind;
}

interface BankGiroData {
  value: BankGiro;
  pos: Pos;
}

enum SearchKind {
  None,
  OrgNumber,
  BgNumber,
  Error,
}

enum Pos {
  Search,
  Selected,
  Current,
}

interface Response {
  paymentAccountKind: PaymentAccountKind;
  paymentAccountNumber: string;
  paymentName: string;
}

export interface InputPaymentDialog {
  paymentAccountKind: PaymentAccountKind;
  paymentAccountNumber: string;
  paymentName: string;
  onSubmit: (response: Response) => Observable<unknown>;
}

@Component({
  templateUrl: './add-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddPaymentDialogComponent implements OnDestroy {
  @ViewChild('qLoading') qLoading: LoadingDirective;
  PaymentAccountKind = PaymentAccountKind;
  SearchKind = SearchKind;
  Pos = Pos;
  currentBGData: BankGiroData[] = [];
  selectedBG: BankGiro;
  sourceIsDown = false;
  cacheClearingNumber: string;
  searching = false;
  readonly loading = 'loadBankGiro';
  paymentKinds: IPaymentKind[] = [
    { title: 'Bankgiro', kind: PaymentAccountKind.Bankgiro },
    { title: $localize`Bankkonto`, kind: PaymentAccountKind.BankAccount },
    {
      title: $localize`Till ett av bolaget angivet konto`,
      kind: PaymentAccountKind.Other,
    },
  ];
  // @ts-expect-error TS2345
  bankGiroDataSub = new BehaviorSubject<BankGiroData[]>(null);
  showbankGiro$: Observable<BankGiroData[]>;
  company$ = this.store.pipe(
    select(selectCompany),
    filter(e => !isNil(e)),
  );
  formControls = {
    paymentAccountKind: new UntypedFormControl(),
    paymentAccountNumber: new UntypedFormControl(),
    searchInput: new UntypedFormControl(),
    name: new UntypedFormControl(),
    bankName: new UntypedFormControl(),
  };
  form = new UntypedFormGroup(this.formControls);

  companyBankGiro$ = this.company$.pipe(
    switchMap(c =>
      this.getBankGiro(this.formatSearchString(c.organizationNumber)).pipe(
        map(res =>
          res.map(r => {
            return {
              value: r,
              pos: Pos.Current,
            } satisfies BankGiroData;
          }),
        ),
      ),
    ),
  );

  selectedBankGiro$ = this.getBankGiro(this.formatSearchString(this.data.paymentAccountNumber)).pipe(
    map(res =>
      res.map(r => {
        return {
          value: r,
          pos: Pos.Selected,
        } satisfies BankGiroData;
      }),
    ),
  );

  currentBGData$ = combineLatest([this.companyBankGiro$, this.selectedBankGiro$]).pipe(
    map(([cBG, sBG]) => [...cBG, ...sBG]),
  );

  bankGiroData$ = this.bankGiroDataSub.pipe(filter(e => !isNil(e))).pipe(
    map(bgs =>
      sortBy(
        uniqBy([...this.currentBGData, ...bgs], bg => bg.value.bgnr),
        s => s.pos,
      ),
    ),
  );

  subs = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputPaymentDialog,
    public dialogRef: MatDialogRef<AddPaymentDialogComponent>,
    private store: Store<AppState>,
    private bankGrioService: BankGrioService,
  ) {
    this.subs.sink = this.formControls.paymentAccountKind.valueChanges.subscribe((kind: PaymentAccountKind) => {
      if (kind === PaymentAccountKind.Bankgiro) {
        this.loadBankGiro();
        this.formControls.searchInput.setValue(data.paymentAccountNumber || '');
        this.formControls.name.setValue(data.paymentName || '');
      }
    });
    this.formControls.paymentAccountKind.setValue(data.paymentAccountKind);
    if (this.formControls.paymentAccountKind.value === PaymentAccountKind.BankAccount) {
      this.formControls.paymentAccountNumber.setValue(data.paymentAccountNumber);
      this.formControls.bankName.setValue(data.paymentName || '');
    }
    this.subs.sink = this.formControls.paymentAccountNumber.valueChanges.subscribe((value: string) => {
      if (this.formControls.paymentAccountKind.value === PaymentAccountKind.BankAccount) {
        this.setBankName(value);
      }
    });
  }

  static open(dialog: MatDialog, data: InputPaymentDialog) {
    return dialog.open<AddPaymentDialogComponent, InputPaymentDialog>(AddPaymentDialogComponent, {
      minWidth: 550,
      disableClose: true,
      autoFocus: false,
      data: data,
    });
  }

  formatSearchString(value: string) {
    return !isNil(value) ? value.replace(/[^0-9]+/g, '') : '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  setBankName(value: string) {
    if (isNil(value)) {
      return;
    }
    const cNumber = value.replace(/\D+/g, '').substring(0, 4);
    if (cNumber.length > 3 && this.cacheClearingNumber !== cNumber) {
      this.formControls.bankName.setValue(bankCode(cNumber)?.name);
      this.cacheClearingNumber = cNumber;
    }
  }

  loadBankGiro() {
    this.qLoading?.start();
    this.currentBGData$
      .pipe(
        map(bgs => {
          const selected = bgs.find(bg => bg.pos === Pos.Selected);
          if (selected) {
            this.selectedBG = selected.value;
          }
          return bgs;
        }),
        finalize(() => this.qLoading?.stop()),
      )
      .pipe(take(1))
      .subscribe(bgs => {
        this.currentBGData = bgs;
        this.bankGiroDataSub.next(bgs);
      });
    this.showbankGiro$ = this.formControls.searchInput.valueChanges.pipe(
      startWith(''),
      map(value => {
        if (value === '') {
          this.bankGiroDataSub.next(this.currentBGData);
        }
        return value;
      }),
      switchMap(value => {
        const filterValue = this.formatSearchString(value);
        return this.bankGiroData$.pipe(
          map(bgs =>
            bgs.filter(
              bg =>
                this.formatSearchString(bg.value.bgnr).startsWith(filterValue) ||
                this.formatSearchString(bg.value.orgnr).startsWith(filterValue),
            ),
          ),
        );
      }),
    );
  }

  submitValid(): boolean {
    const fcs = this.formControls;
    switch (fcs.paymentAccountKind.value) {
      case PaymentAccountKind.BankAccount: {
        return this.formatSearchString(fcs.paymentAccountNumber.value)?.length > 0;
      }
      case PaymentAccountKind.Bankgiro: {
        if (this.sourceIsDown) {
          return [7, 8].includes(this.formatSearchString(this.formControls.searchInput.value).length);
        }
        return !isNil(this.selectedBG) && this.formControls.searchInput.value === this.selectedBG.bgnr;
      }
      case PaymentAccountKind.Other: {
        return true;
      }
      default: {
        // @ts-expect-error TS2322
        return;
      }
    }
  }

  getBankGiro(searchStr: string) {
    return this.bankGrioService.getBankGiro(searchStr).pipe(
      map(res => {
        if (isArray(res)) {
          return res;
        } else {
          this.sourceIsDown = true;
          return [] as BankGiro[];
        }
      }),
    );
  }

  searchLabelKind(): SearchKind {
    const valueLength: number = this.formatSearchString(this.formControls.searchInput?.value).length || 0;
    if (this.sourceIsDown) {
      return SearchKind.Error;
    }
    if ([7, 8].includes(valueLength)) {
      return SearchKind.BgNumber;
    } else if (valueLength === 10) {
      return SearchKind.OrgNumber;
    } else {
      return SearchKind.None;
    }
  }

  searchBankGiro() {
    if (this.sourceIsDown) {
      return;
    }
    const searchInput = this.formControls.searchInput?.value as string;
    const searchStr = this.formatSearchString(searchInput);
    if (![7, 8, 10].includes(searchStr.length)) {
      return;
    } else {
      if (this.selectedBG?.bgnr === searchInput) {
        this.submit();
        return;
      }
      this.searching = true;
      this.subs.sink = this.getBankGiro(searchStr)
        .pipe(first())
        .pipe(
          map(res => {
            return res.map(r => {
              return {
                value: r,
                pos: Pos.Search,
              } satisfies BankGiroData;
            });
          }),
        )
        .subscribe(bgs => {
          this.searching = false;
          if (isEmpty(bgs)) {
            return [];
          } else {
            if (bgs.length === 1) {
              // @ts-expect-error TS2532
              this.selectedBG = _first(bgs).value;
            }
            this.bankGiroDataSub.next(bgs);
          }
        });
    }
  }

  submit() {
    const paymentAccountKind: PaymentAccountKind = this.formControls.paymentAccountKind.value;
    let paymentAccountNumber;
    let paymentName;
    switch (paymentAccountKind) {
      case PaymentAccountKind.Bankgiro: {
        if (this.sourceIsDown) {
          paymentAccountNumber = this.formControls.searchInput.value;
          paymentName = this.formControls.name.value;
        } else {
          paymentAccountNumber = this.selectedBG.bgnr;
          paymentName = this.selectedBG.name;
        }
        break;
      }
      case PaymentAccountKind.BankAccount: {
        paymentAccountNumber = this.formControls.paymentAccountNumber.value;
        paymentName = this.formControls.bankName.value;
        break;
      }
      case PaymentAccountKind.Other: {
        paymentAccountNumber = '';
        break;
      }
      default: {
        break;
      }
    }
    const resp: Response = {
      paymentAccountKind: paymentAccountKind,
      paymentAccountNumber: paymentAccountNumber,
      paymentName: paymentName,
    };
    this.subs.sink = this.data
      .onSubmit(resp)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close();
      });
  }
}
