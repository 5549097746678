import { Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { BlankComponent } from './components/blank.component';
import { RootGuard } from './guards/root.guard';
import { AuthComponent } from './auth/auth.component';

export const appRoutes: Routes = [
  {
    path: 'auth',
    children: [{ path: '**', component: AuthComponent }],
  },
  {
    path: 'onboarding',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./onboarding/onboarding.routes').then(({ onboardingRoutes }) => onboardingRoutes),
  },
  {
    path: 'company',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'startuptools',
    redirectTo: 'free-templates',
    pathMatch: 'full',
  },
  {
    path: 'free-templates',
    loadChildren: () =>
      import('./free-templates/free-templates.routes').then(({ freeTemplatesRoutes }) => freeTemplatesRoutes),
  },
  {
    path: 'change-company/:companyId',
    canActivateChild: [AuthGuard],
    loadComponent: () => import('./change-company/change-company.component').then(c => c.ChangeCompanyComponent),
  },
  { path: '', component: BlankComponent, canActivate: [RootGuard] },
  // { path: '**', redirectTo: '/onboarding' },
];
