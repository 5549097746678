import { NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { AppCommonModule } from '../common.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { QuillModule } from 'ngx-quill';
import { Angulartics2Module } from 'angulartics2';

import { AppDirectivesModule } from '../directives/directives.module';
import { AppPipesModule } from '../pipes/pipes.module';
import { QMessageModule } from '../modules/message/message.module';

import { AddIbanDialogComponent } from '../components/add-payment-dialog/add-iban-dialog.component';
import { AddParticipantDialogComponent } from '../components/add-participant-dialog/add-participant-dialog.component';
import { AddPaymentDialogComponent } from '../components/add-payment-dialog/add-payment-dialog.component';
import { AlertConfirmDialogComponent } from './simple-dialogs/alert-confirm-dialog.component';
import { AlertDialogComponent } from './simple-dialogs/alert-dialog.component';
import { ConfirmDialogComponent } from './simple-dialogs/confirm-dialog.component';
import { ConnectSnrCaseDialogComponent } from './connect-snr-case-dialog/connect-snr-case-dialog.component';
import { CreateCompanyStepperComponent } from './create-company/create-company-stepper.component';
import { CreateShareClassDialogComponent } from './create-share-class/create-share-class-dialog.component';
import { DocumentUploadDialogComponent } from './document-upload-dialog/document-upload-dialog.component';
import { ExcelDownloadDialogComponent } from './excel-download-dialog/excel-download-dialog.component';
import { MaintenanceComponent } from './status/maintenance.component';
import { ObjectInputComponent } from '../components/object-input/object-input.component';
import { OptionsSubscriberDialogComponent } from './options-subscriber-dialog/options-subscriber-dialog.component';
import { PdfPreviewDialogComponent } from './pdf-viewer-ng/pdf-preview-dialog.component';
import { PromptDialogComponent } from './simple-dialogs/prompt-dialog.component';
import { SelectAuthorizationDialogComponent } from './select-authorization-dialog/select-authorization-dialog.component';
import { SelectShareClassDialogComponent } from './select-share-class/select-share-class-dialog.component';
import { TableDialogComponent } from './simple-dialogs/table-dialog.component';
import { BlankComponent } from './blank.component';
import { ReactWrapperComponent } from './react-wrapper/react-wrapper.component';
import { IdentityWrapperComponent } from './identity/identity-wrapper.component';
import { AddIdentityWrapperComponent } from './add-identity-dialog/add-identity-wrapper.component';

const components: Type<unknown>[] = [
  AddIbanDialogComponent,
  AddParticipantDialogComponent,
  AddPaymentDialogComponent,
  AlertConfirmDialogComponent,
  AlertDialogComponent,
  ConfirmDialogComponent,
  ConnectSnrCaseDialogComponent,
  CreateCompanyStepperComponent,
  CreateShareClassDialogComponent,
  DocumentUploadDialogComponent,
  ExcelDownloadDialogComponent,
  MaintenanceComponent,
  ObjectInputComponent,
  OptionsSubscriberDialogComponent,
  PdfPreviewDialogComponent,
  PromptDialogComponent,
  SelectAuthorizationDialogComponent,
  SelectShareClassDialogComponent,
  ConnectSnrCaseDialogComponent,
  AlertConfirmDialogComponent,
  TableDialogComponent,
  BlankComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    AppCommonModule,
    NgxFileDropModule,
    Angulartics2Module,
    QuillModule,
    LetDirective,
    PushPipe,
    QMessageModule,
    AppPipesModule,
    AppDirectivesModule,
    RouterModule,
    IdentityWrapperComponent,
    ReactWrapperComponent,
    AddIdentityWrapperComponent,
  ],
})
export class ComponentsModule {}
