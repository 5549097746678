import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { LoadingModule } from '../../modules/loading/loading.module';
import { AppState } from '../../store/reducers';
import { selectCompanyId } from '../../store/selectors/companies.base';
import { CommonModule } from '@angular/common';
import { ReactWrapperComponent } from '../react-wrapper/react-wrapper.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DocumentDialogContent } from './DocumentDialog';

interface DialogData {
  documentId: string;
}

@Component({
  templateUrl: './document-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LoadingModule, MatDialogModule, MatButtonModule, MatIconModule, ReactWrapperComponent],
})
export class DocumentDialogComponent {
  DocumentDialogContent = DocumentDialogContent;

  documentId = this.data.documentId;

  companyId$ = this.store.pipe(
    select(selectCompanyId),
    filter(e => !isNil(e)),
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private store: Store<AppState>,
  ) {}

  static open(dialog: MatDialog, data: DialogData): MatDialogRef<DocumentDialogComponent, void> {
    return dialog.open(DocumentDialogComponent, {
      maxWidth: '90%',
      width: '90%',
      minHeight: '400px',
      disableClose: true,
      data,
    });
  }
}
