import { NgModule, LOCALE_ID, Inject, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser';
import {
  MatLuxonDateModule,
  LuxonDateAdapter,
  MAT_LUXON_DATE_FORMATS,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from '@angular/material-luxon-adapter';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { LetDirective, PushPipe } from '@ngrx/component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Angulartics2Module } from 'angulartics2';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import {
  init as sentryInit,
  createErrorHandler,
  TraceService,
  replayIntegration,
  browserTracingIntegration,
} from '@sentry/angular';
import { Settings as LuxonSettings } from 'luxon';
import { QuillModule } from 'ngx-quill';
import { JwtService } from './auth/jwt.service';
import { PlatformModule } from '@angular/cdk/platform';
import { AppCommonModule } from './common.module';
import { ComponentsModule } from './components/components.module';
import { InMemoryCacheService } from './common/apollo-memory-cache';
import { isNil } from 'lodash-es';

// Injectables
import { JwtInterceptor } from './injectables/interceptors/jwt-interceptor';
import { VersionCheckInterceptor } from './injectables/interceptors/version-check-interceptor';

// Pipes
import { AppPipesModule } from './pipes/pipes.module';

import { sentryDsn, environment, host, appTitle, gitRev, sentryEnvironment } from './../environments/environment';

import { AppComponent } from './app.component';

import { reducers, metaReducers } from './store/reducers';
import { effects } from './store/effects';
import { appRoutes } from './app.routes';
import { RouterSerializer } from './injectables/router-store.serializer';
import { ReactWrapperComponent } from './components/react-wrapper/react-wrapper.component';
import { OldBrowserComponent } from './old-browser-wrapper.component';

if (!isNil(sentryDsn)) {
  sentryInit({
    dsn: sentryDsn ?? undefined,
    environment: sentryEnvironment,
    release: gitRev,
    attachStacktrace: true,
    autoSessionTracking: true,
    tracesSampleRate: 0.1,
    integrations: [browserTracingIntegration(), replayIntegration()],
  });
}
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    PlatformModule,
    AppCommonModule,
    MatIconModule,
    BrowserModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot({ pageTracking: { clearIds: true } }),
    LetDirective,
    PushPipe,
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(
      { ...reducers, router: routerReducer },
      {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: false,
          strictStateImmutability: false,
        },
      },
    ),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    RouterModule.forRoot(appRoutes, { enableTracing: false }),
    MatLuxonDateModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          [{ align: [] }],
          ['link'],
        ],
      },
    }),
    AppPipesModule,
    ComponentsModule,
    ReactWrapperComponent,
    OldBrowserComponent,
  ],
  providers: [
    { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
    { provide: TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => null, deps: [TraceService], multi: true },
    { provide: RouterStateSerializer, useClass: RouterSerializer },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionCheckInterceptor,
      multi: true,
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: { firstDayOfWeek: 1 },
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink, jwtService: JwtService, inMemoryCache: InMemoryCacheService) => {
        const link = split(
          args => args.query.definitions.some(d => d.kind === 'OperationDefinition' && d.operation === 'subscription'),
          new GraphQLWsLink(
            createClient({
              url: new URL('graphql-ws', host.clientWsUrl).href,
              lazy: true,
              shouldRetry: () => true,
              retryAttempts: Infinity,
              connectionParams: () => ({
                headers: { authorization: `Bearer ${jwtService.jwt}` },
              }),
            }),
          ),
          ApolloLink.from([
            setContext(() => ({
              headers: { Authorization: `Bearer ${jwtService.jwt}` },
            })),
            httpLink.create({
              uri: new URL('/graphql', host.clientUrl).href,
            }),
          ]),
        );
        inMemoryCache.init();
        const cache = inMemoryCache.cache;
        return {
          default: {
            cache,
            link,
            connectToDevTools: !environment.production,
          },
          auth: {
            cache,
            link: ApolloLink.from([
              setContext(() => ({
                headers: { Authorization: `Bearer ${jwtService.jwt}` },
              })),
              httpLink.create({
                uri: new URL('/auth-service/graphql', host.clientUrl).href,
              }),
            ]),
            connectToDevTools: !environment.production,
          },
        } satisfies NamedOptions;
      },
      deps: [HttpLink, JwtService, InMemoryCacheService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    titleService: Title,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    pl: PlatformLocation,
    @Inject(LOCALE_ID) localeId: string,
  ) {
    titleService.setTitle(appTitle);
    let baseUrl: string;
    if (environment.production) {
      baseUrl = `${pl.protocol}//${pl.hostname}${pl.getBaseHrefFromDOM()}`;
    } else {
      baseUrl = `${pl.protocol}//${pl.hostname}:${pl.port}${pl.getBaseHrefFromDOM()}`;
    }
    matIconRegistry.addSvgIcon(
      'flag_sv',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/flags/sv_round.svg`),
    );
    matIconRegistry.addSvgIcon(
      'flag_en',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/flags/en_round.svg`),
    );
    matIconRegistry.addSvgIcon(
      'card_amex',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/card-icons/amex.svg`),
    );
    matIconRegistry.addSvgIcon(
      'card_mc',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/card-icons/mastercard.svg`),
    );
    matIconRegistry.addSvgIcon(
      'card_visa',
      domSanitizer.bypassSecurityTrustResourceUrl(`${baseUrl}assets/card-icons/visa.svg`),
    );
    LuxonSettings.throwOnInvalid = true;
    LuxonSettings.defaultLocale = localeId;
  }
}
