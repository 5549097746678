import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { LoadingDirective } from '../../modules/loading/loading';
import { isNil } from 'lodash-es';

export interface Data<T> {
  title: string;
  text?: string;
  ok: string;
  no?: string;
  cancel: string;
  confirmBtnClass?: string;
  saveFunction?: (confirmed: boolean) => Observable<T>;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent<T> {
  @ViewChild('qLoading') qLoading: LoadingDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data<T>,
    public dialogRef: MatDialogRef<ConfirmDialogComponent<T>>,
  ) {}

  static open<T>(dialog: MatDialog, data: Data<T>, config: MatDialogConfig = {}) {
    return dialog.open<ConfirmDialogComponent<T>, Data<T>, T>(ConfirmDialogComponent, {
      minWidth: 600,
      disableClose: true,
      ...config,
      data: {
        confirmBtnClass: 'btn',
        ...data,
      },
    });
  }

  submit(confirm: boolean) {
    if (!isNil(this.data.saveFunction)) {
      this.qLoading.start();
      this.data
        .saveFunction(confirm)
        .pipe(
          take(1),
          finalize(() => {
            this.qLoading.stop();
          }),
        )
        .subscribe(res => this.dialogRef.close(res));
      return;
    }
    this.dialogRef.close(confirm);
  }
}
